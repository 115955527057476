import styled from 'styled-components';
import colors from '../../../assets/style/colors';

const SelectProductStyles = styled.div`
  width: 700px;

  .mainConatiner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    overflow: auto;
    max-height: 400px;
  }
  .btn {
    background-color: ${colors.mainColor};
    border: none;
    border-radius: 10px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    color: #ffffff;
    font-size: 16px;
    height: 40px;
  }
  .mainBtn {
    background-color: #eeeeee;
    border: none;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    margin-bottom: 10px;
    color: #000000;
    font-size: 16px;
    height: 40px;
    transition: 300ms;
    &:hover {
      background-color: #c9c9c9;
      box-shadow: 5px 5px 15px #b8b8b8;
    }
  }
  .searchBtn {
    background-color: #eeeeee;
    border: none;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    margin-bottom: 10px;
    color: #000000;
    font-size: 16px;
    height: 40px;
    transition: 300ms;
    &:hover {
      background-color: #c9c9c9;
      box-shadow: 5px 5px 15px #b8b8b8;
    }
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    margin-top: 0;
  }
  .miniConatiner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 0 15px 0;
  }
  .input {
    height: 40px;
    box-shadow: -10px 8px 12px 0px rgba(199, 199, 199, 0.75);
    -webkit-box-shadow: -10px 8px 12px 0px rgba(199, 199, 199, 0.75);
    -moz-box-shadow: -10px 8px 12px 0px rgba(199, 199, 199, 0.75);
    font-size: 16px;
    color: #000000;
    border: none;
    border-radius: 10px;
    padding: 0 10px;
    width: 45%;
  }
`;
export default SelectProductStyles;

import styled from 'styled-components';
import colors from '../../assets/style/colors';
import BackImage from '../../assets/images/back.jpg';

const LoginStyle = styled.div`
  .cardAbsolute {
    position: absolute;
    top: 20px;
    right: 30px;
    border-radius: 40px;
    width: 70px;
    height: 70px;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    display: flex;

    .miniLogo {
      width: 50px;
      position: absolute;
    }

    @keyframes animationLoginLoader {
      from {
        transform: rotateZ(0deg);
      }
      to {
        transform: rotateZ(360deg);
      }
    }
  }

  .mainContainer {
    height: 100vh;
    padding: 20px;
    background-size: 150%;
    box-sizing: border-box;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: ${colors.darkMainColor}; */
    background-image: url(${BackImage});
    background-size: cover;

    .text {
      color: green;
    }
    .logoTitle {
      color: rgba(44, 121, 207, 1);
      font-size: 50px;
      text-align: center;
    }
    .container {
      height: 70%;
      width: 70%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      border-radius: 10px;
      background-color: #ffffff;
      box-shadow: 17px 21px 32px -2px rgba(0, 0, 0, 0.75);
      -webkit-box-shadow: 17px 21px 32px -2px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 17px 21px 32px -2px rgba(0, 0, 0, 0.75);
      .img {
        width: 150px;
        height: 150px;
        margin-top: 10px;
      }
      .card {
        width: 90%;
        padding-left: 50px;
        margin-bottom: 50px;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        .label {
          font-size: 20px;
          color: ${colors.blue};
          width: 100px;
        }
        .labelCard {
          width: 90%;
          display: flex;
          justify-content: flex-start;
        }
      }
      .btn {
        padding: 10px 30px;
        border-radius: 5px;
        background-color: ${colors.mainColor};
        color: #ffffff;
        font-size: 18px;
        border: none;
      }
    }
    .passwordCard {
      border: 2px solid ${colors.blue};
      border-radius: 5px;
      margin-top: 10px;
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      background-color: #fffffff2;
      .input2 {
        width: 85%;
        font-size: 20px;
        padding: 10px 0 10px 10px;
        color: ${colors.blue};
        border: none;
        outline: none;
        border-radius: 5px;
      }
      .eyeBtn {
        background-color: #ffffff;
        border: none;
        width: 10%;
        font-size: 18px;
        padding-top: 4px;
        border-radius: 3px;
      }
    }
    .btnCardBottom {
      width: 90%;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
  }
  @keyframes animationForBack {
    0% {
      background-position: bottom left;
    }
    50% {
      background-position: bottom right;
    }
    100% {
      background-position: bottom left;
    }
  }
  .logo {
    width: 70%;
  }
  .miniCard {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .miniCard2 {
    text-align: center;
    ${'' /* .mainTitle {
      font-size: 25px;
      margin: 0;
      padding: 0;
      font-weight: 700;
      color: ${colors.darkMainColor};
    } */}
  }
`;
export default LoginStyle;

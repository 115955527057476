import React, { useState, useEffect, useCallback } from "react";
import { Select, Table, Input, Button, Modal, Spin } from "antd";
import api from "../../../api/index";
import moment from "moment";

const today = moment().format("YYYY-MM-DD");
export default function Tab1() {
	const [invoices, setInvoices] = useState([]);
	const [dailyAvargeInvoices, setDailyAvargeInvoices] = useState([]);
	const [startDate, setStartDate] = useState(moment().startOf("month").format("YYYY-MM-DD"));
	const [endDate, setEndDate] = useState(today);
	const [excelLoading, setExcelLoading] = useState(false);
	const [startDateBestSeller, setStartDateBestSeller] = useState(today);
	const [endDateBestSeller, setEndDateBestSeller] = useState(today);
	const [startDateInvoices, setStartDateInvoices] = useState(today);
	const [endDateInvoices, setEndDateInvoices] = useState(today);
	const [loadindInvoices, setLoadingInvoices] = useState(false);

	const [fdata, setFdata] = useState([]);

	const [ABCData, setABCData] = useState([]);

	const [itemNameABC, setItemNameABC] = useState("");
	const [abcCategory, setAbcCategory] = useState("");
	const [loading2, setLoading2] = useState(false);
	const [loading3, setLoading3] = useState(false);
	const [currentPageNOAction, setCurrentPageNOAction] = useState(0);
	const [pnlReports, setPnlReports] = useState([]);

	const [loading, setLoading] = useState(false);
	const [hasMoreDataNOAction, setHasMoreDataNOAction] = useState(true);
	const [hasMoreDataABC, setHasMoreDataABC] = useState(true);
	const [currentPageABC, setCurrentPageABC] = useState(0);
	const [noActionItems, setNoActionItems] = useState([]);
	const [clickedInvoice, setClickedInvoice] = useState(null);
	const [invoiceModal, setInvoiceModal] = useState(false);

	const [pageSize, setPageSize] = useState(20);

	useEffect(() => {
		getDailyAvarageInvoice();
	}, []);

	useEffect(() => {
		getInvoices(startDateInvoices, endDateInvoices);
		getPnlReports(startDateInvoices, endDateInvoices);
	}, [startDateInvoices, endDateInvoices]);

	useEffect(() => {
		getBestsellerItems(startDateBestSeller, endDateBestSeller);
	}, [startDateBestSeller, endDateBestSeller]);

	useEffect(() => {
		getNoActionItems(currentPageNOAction, pageSize);
	}, [currentPageNOAction, pageSize]);

	useEffect(() => {
		getABCReports(itemNameABC, abcCategory, currentPageABC, pageSize, startDate, endDate);
	}, [itemNameABC, abcCategory, startDate, endDate, currentPageABC, pageSize]);

	const getPnlReports = async (startDate, endDate) => {
		// setLoadingPnl(true);
		try {
			await api.get(`api/pnlreport?startDate=${startDate}&endDate=${endDate}`).then((res) => {
				console.log(JSON.parse(res.data));
				setPnlReports(JSON.parse(res.data));
			});
		} catch (error) {
			console.error(error.message);
		}
		// finally {
		// 	// setLoadingPnl(false);
		// }
	};

	const getInvoices = async (startDate, endDate) => {
		setLoadingInvoices(true);
		try {
			await api.get(`api/sales/sotuvlar?startDate=${startDate}&endDate=${endDate}`).then((res) => {
				console.log(JSON.parse(res.data));
				setInvoices(JSON.parse(res.data));
			});
		} catch (error) {
			console.error(error.message);
		} finally {
			setLoadingInvoices(false);
		}
	};

	const getDailyAvarageInvoice = async () => {
		try {
			await api.get("api/invoicesaverage").then((res) => {
				console.log(JSON.parse(res.data));
				setDailyAvargeInvoices(JSON.parse(res.data));
			});
		} catch (error) {
			console.error(error.message);
		}
	};

	const getBestsellerItems = async (startDate, endDate) => {
		setLoading2(true);
		try {
			await api
				.get(`api/bestselleritems/items?startDate=${startDate}&endDate=${endDate}`)
				.then((res) => {
					console.log(JSON.parse(res.data));
					setFdata(JSON.parse(res.data));
				});
		} catch (error) {
			console.error(error.message);
		}
		setLoading2(false);
	};

	const getNoActionItems = async (page, pageSize) => {
		setLoading3(true);
		try {
			await api
				.get(`api/inventoryitems/no-action-items?pageSize=${pageSize}&pageToken=${page}`)
				.then((res) => {
					const formattedData = JSON.parse(res.data);
					const hasMore = formattedData.length === pageSize ? true : false;
					console.log(hasMore);
					setHasMoreDataNOAction(hasMore);
					setNoActionItems(formattedData);
				});
		} catch (error) {
			console.error(error.message);
		}
		setLoading3(false);
	};

	const getABCReports = async (itmName, abc, page, pageSize, startDate, endDate) => {
		setLoading(true);
		try {
			// abcCategory=${abc}&
			await api
				.get(
					`api/abcreport?abcCategory=${abc}&itemName=${itmName}&startDate=${startDate}&endDate=${endDate}&pageToken=${page}&pageSize=${pageSize}`
				)
				.then((res) => {
					const formattedData = JSON.parse(res.data);
					const hasMore = formattedData.length === pageSize ? true : false;
					console.log(hasMore);
					setHasMoreDataABC(hasMore);
					setABCData(formattedData);
				});
		} catch (error) {
			console.error(error.message);
		}
		setLoading(false);
	};

	const handleSelectChange = (value) => {
		if (value === "kunlik") {
			setStartDateBestSeller(today);
			setEndDateBestSeller(today);
		} else if (value === "haftalik") {
			setStartDateBestSeller(moment().subtract(7, "days").format("YYYY-MM-DD"));
			setEndDateBestSeller(today);
		} else if (value === "oylik") {
			setStartDateBestSeller(moment().startOf("month").format("YYYY-MM-DD"));
			setEndDateBestSeller(today);
		}
	};

	const handleInputChangeABC = useCallback((value) => {
		setCurrentPageABC(0);
		setItemNameABC(value);
	}, []);

	const handleNextPageABC = () => {
		if (hasMoreDataABC) {
			setCurrentPageABC((prevPage) => prevPage + 1);
		}
	};

	const handlePreviousPageABC = () => {
		if (currentPageABC > 0) {
			setCurrentPageABC((prevPage) => prevPage - 1);
		}
	};

	const handleNextPageNOAction = () => {
		if (hasMoreDataNOAction) {
			setCurrentPageNOAction((prevPage) => prevPage + 1);
		}
	};

	const handlePreviousPageNOAction = () => {
		if (currentPageNOAction > 0) {
			setCurrentPageNOAction((prevPage) => prevPage - 1);
		}
	};

	const handleExportExcel = async () => {
		setExcelLoading(true);
		try {
			const res = await api.post(
				"api/abcreport/export-to-excel",
				{},
				{
					headers: {
						"Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					},
					responseType: "blob", // Ensure the response is treated as a blob
				}
			);

			const url = window.URL.createObjectURL(
				new Blob([res.data], {
					type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				})
			);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "ABCKategoriya.xlsx"); // Define the download file name
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

			console.log(res);
		} catch (error) {}
		setExcelLoading(false);
	};

	const handleStartDate = (val) => {
		setStartDate(val);
	};

	const handleEndDate = (val) => {
		setEndDate(val);
	};

	const bestSellerColumns = [
		{
			title: "Mahsulot nomi",
			dataIndex: "itemName",
			key: "itemName",
		},
		{
			title: "Mahsulot soni",
			dataIndex: "quantity",
			key: "quantity",
			render: (text, record) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text).replace(/\s/g, "  ");

				return `${formattedText}`;
			},
		},
		{
			title: "Mahsulot narxi",
			dataIndex: "price",
			key: "price",
			render: (text, record) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text).replace(/\s/g, "  ");

				return `${formattedText}`;
			},
		},
		{
			title: "So'ngi xarid narxi",
			dataIndex: "lastPurPrc",
			key: "lastPurPrc",
			render: (text, record) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text).replace(/\s/g, "  ");

				return `${formattedText}`;
			},
		},
		{
			title: "Umumiy foyda",
			dataIndex: "grssProfit",
			key: "grssProfit",
			render: (text, record) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text).replace(/\s/g, "  ");

				return `${formattedText}`;
			},
		},
		{
			title: "O'lchov birligi",
			dataIndex: "salUnitMsr",
			key: "salUnitMsr",
			text: "center",
		},
	];

	const NoActionItemsColumns = [
		{
			title: "Mahsulot nomi",
			dataIndex: "itemName",
			key: "itemName",
			// filterDropdown: ({ confirm, dataIndex }) => (
			// 	<div className="p-2">
			// 		<Input
			// 			placeholder="Mahsulot bo'yicha qidirish"
			// 			value={itemNameABC}
			// 			onChange={(e) => {
			// 				handleInputChangeABC(e.target.value ? e.target.value : "");
			// 				// handleSearch(e, confirm, dataIndex);
			// 			}}
			// 			style={{ width: 200, marginBottom: 8, display: "block" }}
			// 		/>
			// 	</div>
			// ),
			// onFilter: (value, record) => record.itemName === value,
		},

		{
			title: "Ombordagi qoldiq",
			dataIndex: "onHand",
			key: "onHand",
			render: (text, record) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text).replace(/\s/g, "  ");

				return `${formattedText}`;
			},
		},
		{
			title: "Mahsulot narxi",
			dataIndex: "price",
			key: "price",
			render: (text, record) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text).replace(/\s/g, "  ");

				return `${formattedText}`;
			},
		},
		{
			title: "So'ngi xarid  kuni",
			dataIndex: "latestPurchaseDate",
			key: "latestPurchaseDate",
			render: (text, record) => {
				const formattedText = moment(text).format("DD-MM-YYYY");

				return `${formattedText}`;
			},
		},

		{
			title: "Sotuv kunlari soni",
			dataIndex: "u_SalesDays",
			key: "u_SalesDays",
			text: "center",
		},
		{
			title: "Sotilmagan kunlar soni",
			dataIndex: "noSalesDays",
			key: "noSalesDays",
			text: "center",
		},
	];

	const abcColumns = [
		// {
		// 	title: "Mahsulot guruxi",
		// 	dataIndex: "groupName",
		// 	key: "groupName",
		// 	filterDropdown: () => (
		// 		<Select
		// 			allowClear
		// 			style={{ width: 250 }}
		// 			placeholder="Select Description"
		// 			value={itemGrp}
		// 			onChange={(value) => {
		// 				setCurrentPage(0);
		// 				setItemGrp(value !== undefined ? value : "");
		// 			}}
		// 		>
		// 			<Select.Option key={""} value={""}>
		// 				All
		// 			</Select.Option>
		// 			{idata.map((item) => (
		// 				<Select.Option key={item.itmsGrpNam} value={item.itmsGrpNam}>
		// 					{item.itmsGrpNam}
		// 				</Select.Option>
		// 			))}
		// 		</Select>
		// 	),
		// 	onFilter: (value, record) => record.groupName === value,
		// },
		{
			title: "Mahsulot nomi",
			dataIndex: "itemName",
			key: "itemName",
			filterDropdown: ({ confirm, dataIndex }) => (
				<div className="p-2">
					<Input
						placeholder="Mahsulot bo'yicha qidirish"
						value={itemNameABC}
						onChange={(e) => {
							handleInputChangeABC(e.target.value ? e.target.value : "");
							// handleSearch(e, confirm, dataIndex);
						}}
						style={{ width: 200, marginBottom: 8, display: "block" }}
					/>
				</div>
			),
			onFilter: (value, record) => record.itemName === value,
		},

		{
			title: "Miqdori",
			dataIndex: "quantity",
			key: "quantity",
			render: (text, record) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text).replace(/\s/g, "  ");

				return `${formattedText}`;
			},
		},
		{
			title: "Savdo summasi",
			dataIndex: "sales",
			key: "sales",
			render: (text, record) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text).replace(/\s/g, "  ");

				return `${formattedText}`;
			},
		},
		{
			title: "Savdo foydasi",
			dataIndex: "profit",
			key: "profit",
			render: (text, record) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text).replace(/\s/g, "  ");

				return `${formattedText}`;
			},
		},
		// {
		// 	title: "Yalpi foizi",
		// 	dataIndex: "profitPercent",
		// 	key: "profitPercent",
		// 	text: "center",
		// 	render: (text, record) => {
		// 		const formattedText = new Intl.NumberFormat("fr-FR").format(text);
		// 		return `${formattedText} %`;
		// 	},
		// },
		{
			title: "Foyda foizda",
			dataIndex: "profitPercent",
			key: "profitPercent",
			text: "center",
			render: (text, record) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text);
				return `${formattedText} %`;
			},
		},
		// {
		// 	title: "Kumulyativ foiz",
		// 	dataIndex: "cumulativeProfitPercent",
		// 	key: "cumulativeProfitPercent",
		// 	text: "center",
		// 	render: (text, record) => {
		// 		const formattedText = new Intl.NumberFormat("fr-FR").format(text);
		// 		return `${formattedText} %`;
		// 	},
		// },
		{
			title: "ABC Kategoriya",
			dataIndex: "abcCategory",
			key: "abcCategory",
			filterDropdown: () => (
				<Select
					allowClear
					style={{ width: 150 }}
					placeholder="Select Description"
					value={abcCategory}
					onChange={(value) => {
						setCurrentPageABC(0);
						setAbcCategory(value !== undefined ? value : "");
					}}
				>
					<Select.Option key={""} value={""}>
						Barchasi
					</Select.Option>
					<Select.Option key={"A"} value={"A"}>
						A
					</Select.Option>
					<Select.Option key={"B"} value={"B"}>
						B
					</Select.Option>
					<Select.Option key={"C"} value={"C"}>
						C
					</Select.Option>
				</Select>
			),
			onFilter: (value, record) => record.abcCategory === value,
		},
		// {
		// 	title: "XYZ Kategoriya",
		// 	dataIndex: "XYZCategory",
		// 	key: "XYZCategory",
		// 	filterDropdown: () => (
		// 		<Select
		// 			allowClear
		// 			style={{ width: 150 }}
		// 			placeholder="Select Description"
		// 			value={xyzCategory}
		// 			onChange={(value) => {
		// 				setCurrentPageABC(0);
		// 				setXyzCategory(value !== undefined ? value : "");
		// 			}}
		// 		>
		// 			{/* <Select.Option key={""} value={""}>
		// 				All
		// 			</Select.Option> */}
		// 			<Select.Option key={"X"} vXlue={"X"}>
		// 				X
		// 			</Select.Option>
		// 			<Select.Option key={"Y"} value={"Y"}>
		// 				Y
		// 			</Select.Option>
		// 			<Select.Option key={"Z"} value={"Z"}>
		// 				Z
		// 			</Select.Option>
		// 		</Select>
		// 	),
		// 	onFilter: (value, record) => record.XYZCategory === value,
		// },
	];

	return (
		<>
			<div className="flex items-center gap-8 mt-5">
				<div className="flex flex-col gap-2">
					<p>Boshlang'ich sana</p>
					<Input
						type="date"
						value={startDateInvoices}
						className="w-[150px]"
						onChange={(e) => {
							setStartDateInvoices(e.target.value);
						}}
						max={endDateInvoices}
					/>
				</div>
				<div className="flex flex-col gap-2">
					<p>Tugash sanasi</p>
					<Input
						type="date"
						value={endDateInvoices}
						className="w-[150px]"
						onChange={(e) => {
							setEndDateInvoices(e.target.value);
						}}
						min={startDateInvoices}
						// max={moment}
					/>
				</div>
			</div>
			{loadindInvoices ? (
				<div className="flex items-center justify-center mt-5">
					<Spin />
				</div>
			) : (
				<>
					<div className="grid grid-cols-4 gap-5 mt-5">
						{invoices.map((invoice, idx) => {
							return (
								<>
									<div
										className="flex h-[120px]  w-[250px] flex-col gap-4 items-center rounded-3xl bg-white drop-shadow-xl"
										key={idx}
										onClick={() => {
											setInvoiceModal(true);
											console.log(invoice);
											setClickedInvoice(invoice);
										}}
									>
										<h1 className="font-poppins  mt-5 text-xl font-bold text-[#425166]">
											{invoice.whsName}
										</h1>
										<div>
											{/* <h1 className="font-poppins  text-xl font-bold text-[#151D48]">
									{" "}
									{new Intl.NumberFormat("fr-FR")
										.format(invoice.salesCount)
										.replace(/\s/g, "  ")}{" "}
								</h1> */}
											<h1 className="font-poppins  text-xl font-bold text-[#151D48]">
												{" "}
												{new Intl.NumberFormat("fr-FR")
													.format(invoice.salesDocTotal)
													.replace(/\s/g, "  ")}{" "}
											</h1>
										</div>
									</div>
									{/* {hoveredInvoice && (
								<div className="modal">
									<div className="modal-content">
										<h1 className="font-poppins text-xl font-bold text-[#151D48]">
											{new Intl.NumberFormat("fr-FR")
												.format(hoveredInvoice.salesCount)
												.replace(/\s/g, "  ")}
										</h1>
									</div>
								</div>
							)} */}
								</>
							);
						})}
					</div>
					<div className="grid grid-cols-4 gap-5 mt-10">
						{pnlReports
							.filter(
								(cash) =>
									cash.acctName === "Umumiy Rasxodlar" ||
									cash.acctName === "Foyda" ||
									cash.acctName === "SofFoyda"
							)
							.map((cash, idx) => {
								return (
									<div className="flex h-[120px]  w-[250px] flex-col gap-4 items-center rounded-3xl bg-white drop-shadow-xl">
										<h1 className="font-poppins  mt-5 text-xl font-bold text-[#425166]">
											{cash.acctName === "Arrenda do'kon (toshmi)"
												? "Arenda do'kon (toshmi)"
												: cash.acctName === "SofFoyda"
												? "Sof foyda"
												: cash.acctName === "Umumiy Rasxodlar"
												? "Umumiy Chiqimlar"
												: cash.acctName === "Arrenda do'kon (archa)"
												? "Arenda do'kon (archa)"
												: cash.acctName.replace(/rasxod/g, "chiqim")}
										</h1>

										<h1 className="font-poppins  text-xl font-bold text-[#151D48]">
											{" "}
											{new Intl.NumberFormat("fr-FR").format(cash.summa).replace(/\s/g, "  ")}{" "}
										</h1>
									</div>
								);
							})}
					</div>
				</>
			)}

			<div className="grid grid-cols-4 gap-5 mt-5">
				<div className="flex h-[120px] w-[250px] flex-col gap-3 items-center rounded-3xl bg-white drop-shadow-xl">
					<h1 className="font-poppins  mt-5 text-xl text-center font-bold text-[#425166]">
						Bir kunlik o'rtacha chek
					</h1>

					<h1 className="font-poppins   text-xl font-bold text-[#151D48]">
						{" "}
						{new Intl.NumberFormat("fr-FR")
							.format(dailyAvargeInvoices.averageDocTotal)
							.replace(/\s/g, "  ")}{" "}
					</h1>
				</div>

				<div className="flex flex-col h-[120px] 	 items-center w-[250px]  gap-3 rounded-3xl bg-white drop-shadow-xl">
					<h1 className="font-poppins  mt-5 text-xl text-center font-bold text-[#425166]">
						Bir kunlik sotuvlar soni
					</h1>

					<h1 className="font-poppins   text-xl font-bold text-[#151D48]">
						{" "}
						{new Intl.NumberFormat("fr-FR")
							.format(dailyAvargeInvoices.invoicesCount)
							.replace(/\s/g, "  ")}{" "}
					</h1>
				</div>
			</div>

			<div className="mt-10">
				<div className="flex items-center gap-5">
					<h1 className="text-xl font-bold">10 ta eng ko'p sotilgan mahsulot</h1>
					<Select
						className="flex   items-center  justify-center sm:w-[150px]"
						onChange={(value) => {
							handleSelectChange(value);
						}}
						defaultValue="kunlik"
					>
						<Select.Option value="kunlik">Kunlik</Select.Option>
						<Select.Option value="haftalik">Haftalik</Select.Option>
						<Select.Option value="oylik">Oylik</Select.Option>
					</Select>
				</div>

				<div>
					{/* <div className="flex items-center gap-8 mt-5">
						<div className="flex flex-col gap-2">
							<p>Boshlang'ich sana</p>
							<Input
								type="date"
								value={startDateBestSeller}
								className="w-[150px]"
								onChange={(e) => {
									setStartDateBestSeller(e.target.value);
								}}
								max={endDateBestSeller}
							/>
						</div>
						<div className="flex flex-col gap-2">
							<p>Tugash sanasi</p>
							<Input
								type="date"
								value={endDateBestSeller}
								className="w-[150px]"
								onChange={(e) => {
									setEndDateBestSeller(e.target.value);
								}}
								min={startDateBestSeller}
								// max={moment}
							/>
						</div>
					</div> */}

					<Table
						columns={bestSellerColumns}
						dataSource={fdata}
						pagination={false}
						className="mt-6 w-full "
						bordered
						// rowClassName=" text-center"

						// rowKey="zakaz"
						// sticky={true}
						// scroll={{ x: 900 }}
						loading={loading2}
					/>
				</div>
			</div>

			<div className="mt-10">
				<h1 className="text-xl font-bold">Harakatsiz mahsulotlar</h1>
				<Table
					columns={NoActionItemsColumns}
					dataSource={noActionItems}
					pagination={false}
					className="mt-6 w-full"
					bordered
					loading={loading3}
				/>

				<div className="mb-4 mt-5 flex flex-col justify-between sm:flex-row">
					<div> </div>
					<div className="flex gap-2 items-center sm:mr-10">
						<button
							onClick={handlePreviousPageNOAction}
							disabled={currentPageNOAction === 0}
							className="h-[40px] w-[100px] rounded-2xl bg-gray-300 text-gray-700 disabled:bg-gray-200 disabled:text-gray-400 sm:w-[100px]"
						>
							oldingi
						</button>
						<span>{currentPageNOAction + 1}</span>
						<button
							onClick={handleNextPageNOAction}
							disabled={!hasMoreDataNOAction}
							className="h-[40px] w-[100px] rounded-2xl bg-[#0A4D68] text-white disabled:bg-gray-200 disabled:text-gray-400 sm:w-[100px]"
						>
							keyingi
						</button>
					</div>
				</div>
			</div>

			<div>
				<h1 className="text-lg mt-10 font-semibold">ABC</h1>

				<div className=" mt-5 flex gap-5 items-center justify-between">
					<div className="flex items-center gap-3">
						<div className="flex flex-col gap-3">
							<p>Boshlang'ich sana</p>
							<Input
								type="date"
								value={startDate}
								className="w-[150px]"
								onChange={(e) => {
									handleStartDate(e.target.value);
								}}
								max={endDate}
							/>
						</div>
						<div className="flex flex-col gap-3">
							<p>Tugash sanasi</p>
							<Input
								type="date"
								value={endDate}
								className="w-[150px]"
								onChange={(e) => {
									handleEndDate(e.target.value);
								}}
								min={startDate}
								// max={moment}
							/>
						</div>
					</div>

					<div>
						<Button
							onClick={handleExportExcel}
							loading={excelLoading}
							className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-[200px]"
						>
							Excelga ko'chirish
						</Button>
					</div>
				</div>
				<Table
					columns={abcColumns}
					dataSource={ABCData}
					pagination={false}
					className="mt-6 w-full"
					bordered
					loading={loading}
				/>

				<div className="mb-4 mt-5 flex flex-col justify-between sm:flex-row">
					<div> </div>
					<div className="flex gap-2 items-center sm:mr-10">
						<button
							onClick={handlePreviousPageABC}
							disabled={currentPageABC === 0}
							className="h-[40px] w-[100px] rounded-2xl bg-gray-300 text-gray-700 disabled:bg-gray-200 disabled:text-gray-400 sm:w-[100px]"
						>
							oldingi
						</button>
						<span>{currentPageABC + 1}</span>
						<button
							onClick={handleNextPageABC}
							disabled={!hasMoreDataABC}
							className="h-[40px] w-[100px] rounded-2xl bg-[#0A4D68] text-white disabled:bg-gray-200 disabled:text-gray-400 sm:w-[100px]"
						>
							keyingi
						</button>
					</div>
				</div>
			</div>
			<Modal
				visible={invoiceModal}
				onRequestClose={() => setInvoiceModal(false)}
				onCancel={() => {
					setInvoiceModal(false);
				}}
				style={{
					content: {
						top: "50%",
						left: "50%",
						right: "auto",
						bottom: "auto",
						marginRight: "-50%",
						transform: "translate(-50%, -50%)",
						borderRadius: "10px",
						border: "none",
						padding: "20px",
						backgroundColor: "white",
						outline: "none",
					},
					overlay: {
						background: "#0000008D",
					},
				}}
				contentLabel="Example Modal"
				ariaHideApp={false}
				footer={null}
			>
				{/* <Styles> */}
				<div
					className={
						"flex h-[200px]  w-[400px] flex-col gap-4 items-center  rounded-3xl pl-5 bg-white drop-shadow-xl"
					}
				>
					<div className="flex items-center justify-center">
						<div className="">
							<h1 className="font-poppins  mt-10 text-xl font-bold text-[#425166]">
								Do'kon : {clickedInvoice?.whsName}
							</h1>
							<div className="flex flex-col mt-5 gap-2">
								<h1 className="font-poppins  text-xl font-bold text-[#151D48]">
									Sotuv summasi :{" "}
									{new Intl.NumberFormat("fr-FR")
										.format(clickedInvoice?.salesDocTotal)
										.replace(/\s/g, "  ")}{" "}
								</h1>
								<h1 className="font-poppins  text-xl font-bold text-[#151D48]">
									Sotuvlar soni :{" "}
									{new Intl.NumberFormat("fr-FR")
										.format(clickedInvoice?.salesCount)
										.replace(/\s/g, "  ")}{" "}
								</h1>
							</div>
						</div>
					</div>
				</div>
				{/* </Styles> */}
			</Modal>
		</>
	);
}

import styled from 'styled-components';
import colors from '../../../assets/style/colors';

const Group1Styles = styled.div`
  width: 500px;

  .mainConatiner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: auto;
    max-height: 400px;
  }
  .btn {
    background-color: ${colors.mainColor};
    border: none;
    border-radius: 10px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    color: #ffffff;
    font-size: 16px;
    height: 40px;
  }
  .title{
    font-size: 18px;
    font-weight: bold;
    margin-top: 0;
  }
`;
export default Group1Styles;

import React, { useState, useCallback, useRef } from "react";
import LoginStyle from "./LoginStyle";
import { useDispatch, useSelector } from "react-redux";
import { main } from "../../store/slices";
import api from "../../api";
import { get } from "lodash";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { ErrorModal } from "../../components/Modal";
import { useTranslation } from "react-i18next";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import Logo from "../../assets/images/big_logo.png";
import LoginImage from "../../assets/images/Login2.jpg";
import colors from "../../assets/style/colors";
import axios from "axios";

const Login = () => {
	const { t } = useTranslation();
	// const navigate =useNavigate()
	const {
		setMe,
		setWarehouses,
		setCollapse,
		setSelectedPath,
		setOpenMiniMenu,
		setLoginUser,
		setFirstName,
		setLastName,
		setJobtitle,
	} = main.actions;

	const { loginUser } = useSelector((state) => state.main);
	const loginValue = get(loginUser, "login", "");
	const passwordValue = get(loginUser, "password", "");

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [login, setLogin] = useState(loginValue);
	const [password, setPassword] = useState(passwordValue);
	const [isLoading, setIsLoading] = useState(false);
	const [isShow, setIsShow] = useState(false);

	const errorRef = useRef();
	const getErrorRef = useCallback((ref) => {
		errorRef.current = ref;
	}, []);

	const currentUrl = window.location.href;

	const loginProfile = () => {
		setIsLoading(true);

		// const body = {
		// 	UserName: login,
		// 	Password: password,
		// 	Company: currentUrl === "https://rofod.netlify.app/login" ? "ROFOD_PROD" : "ROFOD_TEST",
		// 	// Company: 'ROFOD_PROD',
		// 	lang: "uz",
		// };

		// console.log(body);

		axios
			.get(
				`https://rofood-dashboard-backend.bis-apps.com/api/auth?EmployeeCode=${login}&ExternalEmployeeNumber=${password}`
			)
			.then((res) => {
				axios.defaults.headers.common["Authorization"] = `Bearer ${get(
					res,
					"data.accessToken",
					""
				)}`;
				console.log(res.data);

				dispatch(setCollapse(false));
				dispatch(setSelectedPath("dashboard"));
				dispatch(setOpenMiniMenu(["dashboard"]));
				dispatch(
					setLoginUser({
						login,
						password,
					})
				);

				let aaa = {
					firstName: get(res, "data.firstName", ""),
					lastName: get(res, "data.lastName", ""),
					token: get(res, "data.accessToken", ""),
					jobTitle: get(res, "data.jobTitle", ""),
				};
				dispatch(setFirstName(get(res, "data.firstName", "")));
				dispatch(setLastName(get(res, "data.lastName", "")));
				dispatch(setJobtitle(get(res, "data.jobTitle", "")));

				dispatch(setMe(aaa));
				dispatch(setMe(aaa));
				dispatch(setMe(aaa));

				dispatch(setMe(aaa));
				navigate("/dashboard");
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				console.log(err);
				errorRef.current?.open("Ошибка логина или пароля");
			});
	};

	// const getWarehouse = () => {
	// 	api
	// 		.get(`Warehouses`, {
	// 			headers: {
	// 				Prefer: "odata.maxpagesize=100",
	// 			},
	// 		})
	// 		.then((res) => {
	// 			const resData = get(JSON.parse(res.data), "value", []);
	// 			dispatch(setWarehouses(resData));
	// 			navigate("/purchaseRequest");
	// 			setIsLoading(false);
	// 		})
	// 		.catch((err) => {
	// 			setIsLoading(false);
	// 			console.log(err);
	// 			errorRef.current?.open("Omborlar topilmadi");
	// 		});
	// };

	return (
		<>
			<LoginStyle>
				<div className="cardAbsolute">
					<img src={Logo} alt="mini Logo" className="miniLogo" />
				</div>
				<div className="mainContainer">
					<div className="container">
						<div style={{ width: "50%" }} className="miniCard2 flex items-center justify-center">
							{/* <div className="flex items-center justify-center">
								<img src={Logo} alt="mini Logo" className="w-[80px]  " />
							</div> */}
							<img src={Logo} alt="logo" className="logo ml-5" />
						</div>
						<div style={{ width: "50%" }}>
							<div className="card">
								<div className="labelCard">
									<label htmlFor="login" className="label">
										{t("Login")}
									</label>
								</div>
								<div className="passwordCard">
									<input
										type="text"
										id="login"
										placeholder={t("Login")}
										defaultValue={loginValue}
										className="input2"
										onChange={(e) => setLogin(e.target.value)}
									/>
								</div>
							</div>
							<div className="card">
								<div className="labelCard">
									<label htmlFor="password" className="label">
										{t("Password")}
									</label>
								</div>
								<div className="passwordCard">
									<input
										type={isShow ? "text" : "password"}
										id="password"
										placeholder={t("Password")}
										defaultValue={passwordValue}
										className="input2"
										onChange={(e) => setPassword(e.target.value)}
									/>
									<button className="eyeBtn" onClick={() => setIsShow((pr) => !pr)}>
										{isShow ? (
											<AiFillEye color={colors.blue} />
										) : (
											<AiFillEyeInvisible color={colors.blue} />
										)}
									</button>
								</div>
								<div className="btnCardBottom">
									<Button onClick={loginProfile} children={t("Вход")} isLoading={isLoading} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</LoginStyle>
			<ErrorModal getRef={getErrorRef} title={t("Ошибка логина или пароля, проверьте еще раз")} />
		</>
	);
};

export default Login;

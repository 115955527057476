import React, { useRef } from "react";
import LayoutStyle from "./LayoutStyle";
import { useNavigate } from "react-router-dom";
import { AiFillBank } from "react-icons/ai";
import { IoDocumentText } from "react-icons/io5";
import {
	FaUser,
	FaMoneyCheckAlt,
	FaShoppingCart,
	FaShoppingBag,
	FaProductHunt,
} from "react-icons/fa";
import { MdDashboard } from "react-icons/md";

import { useTranslation } from "react-i18next";
import { main } from "../../store/slices";
import { RiUserShared2Fill, RiUserReceived2Fill, RiUserLocationFill } from "react-icons/ri";
import { IoLocation } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import UserImage from "../../assets/images/user.png";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { MdRemoveShoppingCart } from "react-icons/md";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { SiMoneygram } from "react-icons/si";
import { IoIosDocument } from "react-icons/io";
import { get } from "lodash";
import { Menu } from "antd";
import "./Style.css";
import { LogoutModal } from "../Modal";

function getItem(label, key, icon, children, type) {
	return {
		key,
		icon,
		children,
		label,
		type,
	};
}

const Layout = ({ children }) => {
	const { setCollapse, setSelectedPath, setOpenMiniMenu } = main.actions;
	const { collapse, selectedPath, openMiniMenu, getMe, fisrtName, lastName, jobTitle } =
		useSelector((state) => state.main);
	const logoutRef = useRef();
	console.log(getMe);

	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const items = [getItem(t("Dashboard"), "dashboard", <MdDashboard color="#ffffff" size={20} />)];

	const toggleCollapsed = () => {
		dispatch(setCollapse(!collapse));
	};

	const onSelectMenuItem = (item) => {
		dispatch(setSelectedPath(item.key));
		navigate(`/${item.key}`);
	};

	const logout = () => {
		navigate("/login");
		dispatch(setCollapse(false));
		dispatch(setSelectedPath("purchaseRequest"));
		dispatch(setOpenMiniMenu(["procurementMenu"]));
	};

	return (
		<LayoutStyle>
			{/* <div className={collapse ? "drawer" : "drawer2"}>
				<button onClick={toggleCollapsed} className="mainBtn">
					{collapse ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
				</button>

				<Menu
					defaultOpenKeys={openMiniMenu}
					selectedKeys={[selectedPath]}
					mode="inline"
					inlineCollapsed={collapse}
					items={items}
					className="mainmenu"
					theme="light"
					onSelect={onSelectMenuItem}
					onOpenChange={(v) => {
						dispatch(setOpenMiniMenu(v));
					}}
				/>
				<div className="relativee">
					<div className="companyCard">
						<p className="companyTitle">BIS</p>
					</div>
				</div>
			</div> */}

			<div className={"containerr"}>
				<div className="headerContainer">
					<div>
						<p className="userName">
							{get(getMe, "lastName", t("Name"))} {get(getMe, "firstName", t("Name"))}
						</p>
						<p className="userPhone">
							{get(getMe, "jobTitle", t("Name")) === "prodmanager" ? t("Supermarket menejeri") : ""}
						</p>
					</div>
					<img
						src={UserImage}
						alt="userimage"
						className="userImg"
						onClick={() => logoutRef.current?.open()}
					/>
				</div>
				{children}
			</div>
			<LogoutModal
				getRef={(r) => {
					logoutRef.current = r;
				}}
				onConfirm={logout}
			/>
		</LayoutStyle>
	);
};

export default Layout;

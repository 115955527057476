import React, { useEffect, useState } from "react";
import { Table, Select, Button, Spin } from "antd";
import api from "../../../api/index";

export default function Tab2() {
	const [cashReports, setCashReports] = useState([]);
	const [totalSum, setTotalSum] = useState([]);
	const [debitCredit, setDebitCredit] = useState(null);
	const [wdata, setWdata] = useState([]);
	const [idata, setIdata] = useState([]);
	const [subData, setSubData] = useState([]);
	const [excelLoading, setExcelLoading] = useState(false);

	const [inventoryData, setInventoryData] = useState([]);
	const [whs, setWhs] = useState("");
	const [itemGrp, setItemGrp] = useState("");

	const [inventoryDataByGroup, setInventoryDataByGroup] = useState([]);

	const [inventoryDataByGroupSub, setInventoryDataByGroupSub] = useState([]);
	const [expandedRowKeys, setExpandedRowKeys] = useState(null);
	const [subExpandedRowKeys, setSubExpandedRowKeys] = useState({});

	const [loading2, setLoading2] = useState(false);
	const [loading3, setLoading3] = useState(false);
	const [loading4, setLoading4] = useState(false);
	const [loading5, setLoading5] = useState(false);

	const [loading, setLoading] = useState(false);
	// const [loading2, setLoading2] = useState(false);

	useEffect(() => {
		getDebitCredit();
		getTotalSum();
		getWhs();
		getCashReports();
		getItemGroup();
		// getSubItemGroup();
	}, []);

	useEffect(() => {
		getInventoryItems(itemGrp, whs);
	}, [itemGrp, whs]);

	const getDebitCredit = async () => {
		try {
			await api.get("api/debitcredit/debit-credit").then((res) => {
				setDebitCredit(JSON.parse(res.data));
			});
		} catch (error) {
			console.error(error.message);
		}
	};

	const getTotalSum = async () => {
		setLoading5(true);
		try {
			await api.get("api/inventoryitems/get-total-sum").then((res) => {
				setTotalSum(JSON.parse(res.data));
			});
		} catch (error) {
			console.error(error.message);
		} finally {
			setLoading5(false);
		}
	};

	const getCashReports = async () => {
		try {
			await api.get("api/cashreposts").then((res) => {
				setCashReports(JSON.parse(res.data));
			});
		} catch (error) {
			console.error(error.message);
		}
	};

	const getInventoryItems = async (itmGrp, whs) => {
		setLoading2(true);
		try {
			await api
				.get(`api/inventoryitems/get-with-group?itemGroup=${itmGrp}&whsName=${whs}`)
				.then((res) => {
					const formattedData = JSON.parse(res.data).map((item, index) => ({
						...item,
						key: index,
					}));
					setInventoryData(formattedData);
				});
		} catch (error) {
			console.error(error.message);
		}
		setLoading2(false);
	};

	const getInventoryItemsByGroup = async (itmGrp, subItmGrp, whs) => {
		setLoading3(true);
		try {
			await api
				.get(
					`api/inventoryitems/get-by-group?itemGroup=${itmGrp}&subGroup=${subItmGrp}&whsName=${whs}`
				)
				.then((res) => {
					const parsedData = JSON.parse(res.data);

					const formattedData = parsedData.map((item, index) => ({
						...item,
						key: index,
					}));

					const subGroupData = formattedData.map((item) => item.subGroup);

					setInventoryDataByGroup(formattedData);
					console.log(subGroupData);
					setSubData(subGroupData);
				});
		} catch (error) {
			console.error(error.message);
		}
		setLoading3(false);
	};

	const getInventoryItemsByGroupSub = async (itmGrp, subItmGrp, whs) => {
		setLoading4(true);
		try {
			await api
				.get(
					`api/inventoryitems/get-by-sub-group?itemGroup=${itmGrp}&subGroup=${subItmGrp}&whsName=${whs}`
				)
				.then((res) => {
					const formattedData = JSON.parse(res.data).map((item, index) => ({
						...item,
						key: index,
					}));
					setInventoryDataByGroupSub(formattedData);
				});
		} catch (error) {
			console.error(error.message);
		}
		setLoading4(false);
	};

	const getWhs = async () => {
		setLoading(true);
		try {
			await api.get("api/inventoryitems/warehouses").then((res) => {
				setWdata(JSON.parse(res.data));
			});
		} catch (error) {
			console.error(error.message);
		}
		setLoading(false);
	};

	const getItemGroup = async () => {
		setLoading(true);
		try {
			await api.get("api/inventoryitems/item-groups").then((res) => {
				setIdata(JSON.parse(res.data));
			});
		} catch (error) {
			console.error(error.message);
		}
		setLoading(false);
	};
	const handleExportExcel = async () => {
		setExcelLoading(true);
		try {
			const res = await api.post(
				"api/inventoryitems/export-to-excel",
				{},
				{
					headers: {
						"Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					},
					responseType: "blob", // Ensure the response is treated as a blob
				}
			);

			const url = window.URL.createObjectURL(
				new Blob([res.data], {
					type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				})
			);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "OmborQoldig'i.xlsx"); // Define the download file name
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

			console.log(res);
		} catch (error) {}
		setExcelLoading(false);
	};

	// const getSubItemGroup = async () => {
	// 	setLoading(true);
	// 	try {
	// 		await api.get("api/inventoryitems/item-sub-groups").then((res) => {
	// 			setSubData(JSON.parse(res.data));
	// 		});
	// 	} catch (error) {
	// 		console.error(error.message);
	// 	}
	// 	setLoading(false);
	// };

	const inventoryitemsColumns = [
		{
			title: "Mahsulot guruhi",
			dataIndex: "groupName",
			key: "groupName",
			filterDropdown: () => (
				<Select
					allowClear
					style={{ width: 250 }}
					placeholder="Select Description"
					value={itemGrp}
					showSearch
					optionFilterProp="children"
					filterOption={(input, option) =>
						option.children.toLowerCase().includes(input.toLowerCase())
					}
					onChange={(value) => {
						setItemGrp(value !== undefined ? value : "");
					}}
				>
					<Select.Option key={""} value={""}>
						Barchasi
					</Select.Option>
					{idata.map((item) =>
						item.itmsGrpNam !== null ? (
							<Select.Option key={item.itmsGrpNam} value={item.itmsGrpNam}>
								{item.itmsGrpNam}
							</Select.Option>
						) : (
							""
						)
					)}
				</Select>
			),
			onFilter: (value, record) => record.groupName === value,
		},
		{
			title: "Summa",
			dataIndex: "onHandTotal",
			key: "onHandTotal",
			text: "center",
			render: (text) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text).replace(/\s/g, "   ");
				return `${formattedText}`;
			},
		},
		{
			title: "Ombor",
			dataIndex: "whsName",
			key: "whsName",
			render: (text) => {
				return `${text.replace(/ToshMI/g, "Toshmi")}`;
			},
			filterDropdown: () => (
				<Select
					allowClear
					style={{ width: 150 }}
					placeholder="Select Description"
					value={whs}
					onChange={(value) => {
						setWhs(value !== undefined ? value : "");
					}}
				>
					<Select.Option key={""} value={""}>
						Barchasi
					</Select.Option>
					{wdata.map((item) => (
						<Select.Option key={item.whsName} value={item.whsName}>
							{item.whsName}
						</Select.Option>
					))}
				</Select>
			),
			onFilter: (value, record) => record.whsName === value,
		},
	];

	const inventoryitemsColumnsByGroup = [
		{
			title: "Mahsulot guruhi",
			dataIndex: "groupName",
			key: "groupName",
		},
		{
			title: "Ikkinchi darajali guruhi",
			dataIndex: "subGroup",
			key: "subGroup",
			// filterDropdown: () => (
			// 	<Select
			// 		allowClear
			// 		style={{ width: 250 }}
			// 		placeholder="Select Description"
			// 		value={itemGrpByGroup}
			// 		showSearch
			// 		optionFilterProp="children"
			// 		filterOption={(input, option) =>
			// 			option.children.toLowerCase().includes(input.toLowerCase())
			// 		}
			// 		onChange={(value) => {
			// 			setItemGrpByGroup(value !== undefined ? value : "");
			// 		}}
			// 	>
			// 		<Select.Option key={""} value={""}>
			// 			Barchasi
			// 		</Select.Option>
			// 		{subData.map((item) =>
			// 			item !== null ? (
			// 				<Select.Option key={item} value={item}>
			// 					{item}
			// 				</Select.Option>
			// 			) : (
			// 				""
			// 			)
			// 		)}
			// 	</Select>
			// ),
			// onFilter: (value, record) => record.subGroup === value,
		},
		{
			title: "Summa",
			dataIndex: "onHandTotal",
			key: "onHandTotal",
			text: "center",
			render: (text) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text).replace(/\s/g, "   ");
				return `${formattedText}`;
			},
		},
		{
			title: "Ombor",
			dataIndex: "whsName",
			key: "whsName",
			render: (text) => {
				return `${text.replace(/ToshMI/g, "Toshmi")}`;
			},
		},
	];

	const inventoryitemsColumnsByGroupSub = [
		{
			title: "Mahsulot guruhi",
			dataIndex: "groupName",
			key: "groupName",
		},
		{
			title: "Ikkinchi darajali guruhi",
			dataIndex: "subGroup",
			key: "subGroup",
		},
		{
			title: "Mahsulot nomi",
			dataIndex: "itemName",
			key: "itemName",
		},
		{
			title: "Bir birlik uchun narx",
			dataIndex: "unitPrice",
			key: "unitPrice",
			text: "center",
			render: (text) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text).replace(/\s/g, "   ");
				return `${formattedText}`;
			},
		},
		{
			title: "Umumiy narx",
			dataIndex: "onHandTotal",
			key: "onHandTotal",
			text: "center",
			render: (text) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text).replace(/\s/g, "   ");
				return `${formattedText}`;
			},
		},
		{
			title: "Ombor",
			dataIndex: "whsName",
			key: "whsName",
			render: (text) => {
				return `${text.replace(/ToshMI/g, "Toshmi")}`;
			},
			// filterDropdown: () => (
			// 	<Select
			// 		allowClear
			// 		style={{ width: 150 }}
			// 		placeholder="Select Description"
			// 		value={whs}
			// 		onChange={(value) => {
			// 			setWhs(value !== undefined ? value : "");
			// 		}}
			// 	>
			// 		<Select.Option key={""} value={""}>
			// 			Barchasi
			// 		</Select.Option>
			// 		{wdata.map((item) => (
			// 			<Select.Option key={item.whsName} value={item.whsName}>
			// 				{item.whsName}
			// 			</Select.Option>
			// 		))}
			// 	</Select>
			// ),
			// onFilter: (value, record) => record.whsName === value,
		},
	];

	const onExpand = async (expanded, record) => {
		if (expanded) {
			await getInventoryItemsByGroup(record.groupName, "", record.whsName);
			setExpandedRowKeys([record.key]);
		} else {
			setExpandedRowKeys([]);
		}
	};

	const onExpandSub = async (expanded, record, parentKey) => {
		if (expanded) {
			await getInventoryItemsByGroupSub(record.groupName, record.subGroup, record.whsName);

			setSubExpandedRowKeys((prevState) => ({
				...prevState,
				[parentKey]: [record.key],
			}));
		} else {
			setSubExpandedRowKeys((prevState) => ({
				...prevState,
				[parentKey]: [],
			}));
		}
	};
	return (
		<>
			{" "}
			<div className="flex items-center gap-7">
				<div className="flex h-[120px] w-[265px] flex-col gap-4 items-center rounded-3xl bg-white drop-shadow-xl">
					<h1 className="font-poppins  mt-5 text-xl font-bold text-[#425166]">
						Kreditor qarzdorlik
					</h1>

					<h1 className="font-poppins  text-xl font-bold text-[#151D48]">
						{" "}
						{new Intl.NumberFormat("fr-FR").format(debitCredit?.credit).replace(/\s/g, "  ")}{" "}
					</h1>
				</div>
			</div>
			{loading5 ? (
				<div className="flex items-center justify-center mt-5">
					<Spin />
				</div>
			) : (
				<>
					<div className="grid grid-cols-4 gap-5 mt-10">
						{cashReports.map((cash, idx) => {
							return (
								<div className="flex h-[120px] w-[265px] flex-col gap-4 items-center rounded-3xl bg-white drop-shadow-xl">
									<h1 className="font-poppins  mt-5 text-xl font-bold text-[#425166]">
										{cash.acctName.replace(/ToshMI/g, "Toshmi")}
									</h1>

									<h1 className="font-poppins  text-xl font-bold text-[#151D48]">
										{" "}
										{new Intl.NumberFormat("fr-FR")
											.format(cash.currTotal)
											.replace(/\s/g, "  ")}{" "}
									</h1>
								</div>
							);
						})}
					</div>
					<div className="grid grid-cols-4 gap-5 mt-10">
						{totalSum.map((cash, idx) => {
							return (
								<div className="flex h-[120px] w-[265px] flex-col gap-4 items-center rounded-3xl bg-white drop-shadow-xl">
									<h1 className="font-poppins  mt-5 text-xl font-bold text-[#425166]">
										{cash.whsName.replace(/ToshMI/g, "Toshmi")} mahsulot
									</h1>

									<h1 className="font-poppins  text-xl font-bold text-[#151D48]">
										{" "}
										{new Intl.NumberFormat("fr-FR")
											.format(cash.onHandTotal)
											.replace(/\s/g, "  ")}{" "}
									</h1>
								</div>
							);
						})}
					</div>
				</>
			)}
			<div className="flex  items-center justify-between mt-20">
				<h1 className="text-lg  font-semibold">Ombor qoldig'i</h1>

				<div>
					{" "}
					<Button
						onClick={handleExportExcel}
						loading={excelLoading}
						className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-[200px]"
					>
						Excelga ko'chirish
					</Button>
				</div>
			</div>
			<Table
				columns={inventoryitemsColumns}
				dataSource={inventoryData}
				rowKey={(record) => record.key}
				pagination={false}
				loading={loading2}
				className="mt-10"
				expandedRowKeys={expandedRowKeys}
				onExpand={onExpand}
				expandedRowRender={(record) => (
					<Table
						columns={inventoryitemsColumnsByGroup}
						dataSource={inventoryDataByGroup}
						rowKey={(record) => record.key}
						expandedRowKeys={subExpandedRowKeys[record.key] || []}
						pagination={false}
						loading={loading3}
						onExpand={(expanded, subRecord) => onExpandSub(expanded, subRecord, record.key)}
						expandedRowRender={(record) => (
							<Table
								columns={inventoryitemsColumnsByGroupSub}
								dataSource={inventoryDataByGroupSub}
								rowKey={(record) => record.key}
								pagination={false}
								loading={loading4}
							/>
						)}
					/>
				)}
			/>
		</>
	);
}

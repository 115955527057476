import React, { useState, useEffect, useCallback } from "react";
import { Table, Input, Spin } from "antd";
import api from "../../../api/index";
import moment from "moment";

const today = moment().format("YYYY-MM-DD");

export default function Tab3() {
	const [pnlReports, setPnlReports] = useState([]);
	const [loading, setLoading] = useState(false);
	const [loadingPnl, setLoadingPnl] = useState(false);

	const [salesAnalysisData, setSalesAnalysisData] = useState([]);
	const [salesAnalysGrp, setSalesAnalysGrp] = useState("");
	const [currentPageSales, setCurrentPageSales] = useState(0);
	const [startDatePnl, setStartDatePnl] = useState(moment().startOf("month").format("YYYY-MM-DD"));
	const [endDatePnl, setEndDatePnl] = useState(today);

	useEffect(() => {
		getSalesAnalysis(salesAnalysGrp);
	}, [salesAnalysGrp]);

	useEffect(() => {
		getPnlReports(startDatePnl, endDatePnl);
	}, [startDatePnl, endDatePnl]);

	const getPnlReports = async (startDate, endDate) => {
		setLoadingPnl(true);
		try {
			await api.get(`api/pnlreport?startDate=${startDate}&endDate=${endDate}`).then((res) => {
				console.log(JSON.parse(res.data));
				setPnlReports(JSON.parse(res.data));
			});
		} catch (error) {
			console.error(error.message);
		} finally {
			setLoadingPnl(false);
		}
	};

	const handleInputChangeSalesAnalys = useCallback((value) => {
		setCurrentPageSales(0);
		setSalesAnalysGrp(value);
	}, []);

	const getSalesAnalysis = async (itmGrp) => {
		setLoading(true);
		try {
			// &pageToken=${page}&pageSize=${pageSize}
			await api.get(`api/salesanalysis?groupName=${itmGrp}`).then((res) => {
				// const formattedData = JSON.parse(res.data);
				// const hasMore = formattedData.length === pageSize ? true : false;
				// console.log(hasMore);
				// setHasMoreData(hasMore);
				setSalesAnalysisData(JSON.parse(res.data));
			});
		} catch (error) {
			console.error(error.message);
		}
		setLoading(false);
	};

	// const cashPnlColumns = [
	// 	{
	// 		title: "Statya",
	// 		dataIndex: "acctName",
	// 		key: "acctName",
	// 		// filterDropdown: () => (
	// 		// 	<Select
	// 		// 		allowClear
	// 		// 		style={{ width: 250 }}
	// 		// 		placeholder="Select Description"
	// 		// 		value={itemGrp}
	// 		// 		onChange={(value) => {
	// 		// 			// setCurrentPage(0);
	// 		// 			setItemGrp(value !== undefined ? value : "");
	// 		// 		}}
	// 		// 	>
	// 		// 		<Select.Option key={""} value={""}>
	// 		// 			Barchasi
	// 		// 		</Select.Option>
	// 		// 		{idata.map((item) =>
	// 		// 			item.itmsGrpNam !== null ? (
	// 		// 				<Select.Option key={item.itmsGrpNam} value={item.itmsGrpNam}>
	// 		// 					{item.itmsGrpNam}
	// 		// 				</Select.Option>
	// 		// 			) : (
	// 		// 				""
	// 		// 			)
	// 		// 		)}
	// 		// 	</Select>
	// 		// ),
	// 		// onFilter: (value, record) => record.groupName === value,
	// 		render: (text, record) => {
	// 			const formattedText =
	// 				text === "Arrenda do'kon (toshmi)"
	// 					? "Arenda do'kon (toshmi)"
	// 					: text === "SofFoyda"
	// 					? "Sof foyda"
	// 					: text === "Arrenda do'kon (archa)"
	// 					? "Arenda do'kon (archa)"
	// 					: text.replace(/rasxod/g, "chiqim");
	// 			return `${formattedText}`;
	// 		},
	// 	},

	// 	{
	// 		title: "Summa",
	// 		dataIndex: "summa",
	// 		key: "summa",
	// 		text: "center",
	// 		render: (text, record) => {
	// 			const formattedText = new Intl.NumberFormat("fr-FR").format(text).replace(/\s/g, "   ");
	// 			return `${formattedText}`;
	// 		},
	// 	},
	// ];

	const salesAnalysColumns = [
		{
			title: "Mahsulot guruhi",
			dataIndex: "group",
			key: "group",
			filterDropdown: ({ confirm, dataIndex }) => (
				<div className="p-2">
					<Input
						placeholder="Mahsulot bo'yicha qidirish"
						value={salesAnalysGrp}
						onChange={(e) => {
							handleInputChangeSalesAnalys(e.target.value ? e.target.value : "");
							// handleSearch(e, confirm, dataIndex);
						}}
						style={{ width: 200, marginBottom: 8, display: "block" }}
					/>
				</div>
			),
			onFilter: (value, record) => record.group === value,
		},

		{
			title: "Soni",
			dataIndex: "quantity",
			key: "quantity",
			text: "center",
			render: (text, record) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text).replace(/\s/g, "   ");
				return `${formattedText}`;
			},
		},
		{
			title: "Summa",
			dataIndex: "sales",
			key: "sales",
			text: "center",
			render: (text, record) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text).replace(/\s/g, "   ");
				return `${formattedText}`;
			},
		},
		{
			title: "Foyda",
			dataIndex: "profit",
			key: "profit",
			text: "center",
			render: (text, record) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text).replace(/\s/g, "   ");
				return `${formattedText}`;
			},
		},
		{
			title: "Foyda foizda",
			dataIndex: "grossPercent",
			key: "grossPercent",
			render: (text, record) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text).replace(/\s/g, "   ");
				return `${formattedText} %`;
			},
		},
	];
	return (
		<>
			<h1 className="text-lg mt-10 font-semibold ml-2">P&L</h1>
			<div className="flex items-center gap-8 mt-5">
				<div className="flex flex-col gap-2">
					<p>Boshlang'ich sana</p>
					<Input
						type="date"
						value={startDatePnl}
						className="w-[150px]"
						onChange={(e) => {
							setStartDatePnl(e.target.value);
						}}
						max={endDatePnl}
					/>
				</div>
				<div className="flex flex-col gap-2">
					<p>Tugash sanasi</p>
					<Input
						type="date"
						value={endDatePnl}
						className="w-[150px]"
						onChange={(e) => {
							setEndDatePnl(e.target.value);
						}}
						min={startDatePnl}
						// max={moment}
					/>
				</div>
			</div>
			{loadingPnl ? (
				<div className="flex items-center justify-center mt-5">
					<Spin />
				</div>
			) : (
				<>
					<div className="grid grid-cols-4 gap-5 mt-10">
						{pnlReports.map((cash, idx) => {
							return (
								<div className="flex h-[120px]  w-[250px] flex-col gap-4 items-center rounded-3xl bg-white drop-shadow-xl">
									<h1 className="font-poppins  mt-5 text-xl font-bold text-[#425166]">
										{cash.acctName === "Arrenda do'kon (toshmi)"
											? "Arenda do'kon (toshmi)"
											: cash.acctName === "SofFoyda"
											? "Sof foyda"
											: cash.acctName === "Umumiy Rasxodlar"
											? "Umumiy Chiqimlar"
											: cash.acctName === "Arrenda do'kon (archa)"
											? "Arenda do'kon (archa)"
											: cash.acctName.replace(/rasxod/g, "chiqim")}
									</h1>

									<h1 className="font-poppins  text-xl font-bold text-[#151D48]">
										{" "}
										{new Intl.NumberFormat("fr-FR").format(cash.summa).replace(/\s/g, "  ")}{" "}
									</h1>
								</div>
							);
						})}
					</div>
				</>
			)}

			{/* <Table
      columns={cashPnlColumns}
      dataSource={pnlReports}
      pagination={false}
      className="mt-6 w-[650px] "
      bordered
      // rowClassName=" text-center"

      // rowKey="zakaz"
      // sticky={true}
      // scroll={{ x: 900 }}
      loading={loading2}
    />{" "}
     */}
			<h1 className="text-lg mt-10 font-semibold">Sotuv analizi </h1>
			<div>
				<Table
					columns={salesAnalysColumns}
					dataSource={salesAnalysisData}
					pagination={false}
					className="mt-6 w-full"
					bordered
					sticky={true}
					// scroll={{ x: 900 }}
					loading={loading}
				/>
			</div>
		</>
	);
}
